import React, { useEffect, useState } from 'react'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import styles from './styles.module.scss'
import { TransitionLink } from '@/components/parts/TransitionLink'
import { useLocation } from 'react-router-dom'

export function NotFound(): JSX.Element {
  const location = useLocation()
  const [isTransition, setIsTransition] = useState(false)

  useEffect(() => {
    // TELASA 用ページの場合は TELASA 用の Not Found へリダイレクト
    if (location.pathname.startsWith('/telasa')) {
      setIsTransition(true)
      window.open(
        (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/telasa/errors/notfound/',
        '_parent',
        'noreferrer',
      )
    }
    if (location.pathname.startsWith('/alpha-u')) {
      setIsTransition(true)
      window.open(
        (process.env.REACT_APP_TOP_PAGE_URL ?? '') +
          '/alpha-u/errors/notfound/',
        '_parent',
        'noreferrer',
      )
    }
  }, [location])

  // TELASA 用 Not Found ページにリダイレクトする際 au Live Streaming のエラーページを見せないようにする
  if (isTransition) {
    return <></>
  }

  return (
    <div className={styles.pageWrapper}>
      <Header />
      <div className={styles.contentsWrap}>
        <div>
          <div className={styles.header}>
            <p>404</p>
            <p>ページが見つかりませんでした</p>
          </div>
          <div className={styles.message}>
            <p>
              {`アクセスいただいたページは、削除、変更されたか、現在利用できない可能性があります。`}
            </p>
            <p>{`お手数ですが、トップページよりお探しくださいますようお願いいたします。`}</p>
          </div>
          <div className={styles.linkWrap}>
            <TransitionLink
              text="TOPページに戻る"
              transitionUrl={process.env.REACT_APP_TOP_PAGE_URL ?? ''}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
