import React from 'react'
import logo from '@/assets/images/logo.svg'
import styles from './styles.module.scss'

export function Header(): JSX.Element {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <a href={process.env.REACT_APP_TOP_PAGE_URL}>
          <img src={logo} alt="au Live Streaming" />
        </a>
      </div>
      <div className={styles.navi}>
        <ul>
          <li>
            <a
              href={
                (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/notification/'
              }
            >
              お知らせ
            </a>
          </li>
          <li>
            <a href={(process.env.REACT_APP_BASE_URL ?? '') + '/mypage/'}>
              マイページ
            </a>
          </li>
        </ul>
      </div>
    </header>
  )
}
