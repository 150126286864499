import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import { TelasaHeader } from '@/components/parts/TELASA/Header'
import { TelasaFooter } from '@/components/parts/TELASA/Footer'
import { AuHeader } from '@/components/parts/alpha-u/Header'
import { AuFooter } from '@/components/parts/alpha-u/Footer'
import { Error } from '@/components/parts/Error'
import styles from './styles.module.scss'
import { TicketType, SitePath } from '@/enums'

const cancelCode = 'MPL40200'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export const PurchaseError = (props: Props): JSX.Element => {
  const { id } = useParams()
  const query = new URLSearchParams(location.search)
  const code = query.get('X-ResultCd')
  const [isTransition, setIsTransition] = useState(false)

  // siteCode 0:au Live Streaming 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.als

  useEffect(() => {
    // 購入キャンセル時は記事ページに飛ばす
    if (code === cancelCode && id !== undefined) {
      setIsTransition(true)
      window.open(
        (process.env.REACT_APP_TOP_PAGE_URL ?? '') +
          SitePath(siteCode) +
          '/live/' +
          (id ?? ''),
        '_parent',
        'noreferrer',
      )
    }
  }, [id, code])

  // 記事ページに遷移する際にエラーページを見せないようにする
  if (isTransition) {
    return <></>
  }

  return (
    <div
      className={`${
        siteCode === TicketType.alpha_u
          ? styles.pageWrapperAu
          : siteCode === TicketType.telasa
          ? styles.pageWrapperTelasa
          : styles.pageWrapperUp
      }`}
    >
      {siteCode === TicketType.als && <Header />}
      {siteCode === TicketType.telasa && <TelasaHeader />}
      {siteCode === TicketType.alpha_u && <AuHeader />}
      <div className={styles.contentsWrap}>
        <Error
          message={'お手数ですがもう一度最初から\n購入のお手続きをお願いします'}
          code={code ?? undefined}
          buttonTitle="元のページに戻る"
          link={
            (process.env.REACT_APP_TOP_PAGE_URL ?? '') +
            SitePath(siteCode) +
            '/live/' +
            (id ?? '')
          }
          mode={siteCode === TicketType.als ? 'dark' : 'light'}
        />
      </div>
      {siteCode === TicketType.als && <Footer />}
      {siteCode === TicketType.telasa && <TelasaFooter />}
      {siteCode === TicketType.alpha_u && <AuFooter />}
    </div>
  )
}
