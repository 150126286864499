import React, { useEffect, useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Live } from './pages/Live'
import { ViewingConfirmation } from './pages/ViewingConfirmation'
import { PagesSelectContents } from './pages/PagesSelectContents'
import { PageConfirm } from './pages/PageConfirm'
import { PurchaseCompleted } from './pages/PurchaseCompleted'
import { PurchaseError } from './pages/PurchaseError'
import { ViewingError } from './pages/ViewingError'
import { CommonError } from './pages/Error'
import { PageOffHours } from './pages/PageOffHours'
import { MyPage } from './pages/MyPage'
import { LivePreview } from './pages/Preview'
import { NotFound } from './pages/NotFound'
import { Login } from './pages/Login'
// 決済方法選択
import { Payment } from './pages/Payment'
// 個人情報保持
import { PersonalData } from './pages/PersonalData'
import { MyPageSetting } from './pages/MyPage/Setting'
import { PageAnnounce } from './pages/Announce'
import { TicketType } from '@/enums'

import {
  SharedContext,
  SharedContextProvider,
} from '@/components/parts/SharedContext'

function App(): JSX.Element {
  const sharedData = useContext(SharedContext)

  const path = window.location.pathname
  if (path.startsWith('/telasa')) {
    sharedData.siteCode = TicketType.telasa
  } else if (path.startsWith('/alpha-u')) {
    sharedData.siteCode = TicketType.alpha_u
  }

  useEffect(() => {
    const setMetaContent = (
      attribute: string,
      value: string,
      content: string,
    ) => {
      const elm = document.querySelector(
        'meta[' + attribute + '="' + value + '"]',
      )
      if (elm != null) {
        elm.setAttribute('content', content)
      }
    }

    // au Live Streaming 用ページと TELASA 用ページで favicon を切り替えなければならない...
    const obj = document.getElementById('fav') as HTMLAnchorElement
    const elmtitle = document.getElementsByTagName('title')[0]

    if (sharedData.siteCode === TicketType.telasa) {
      obj.href = '/favicon-telasa.ico'
      elmtitle.text =
        'オンライン配信 | ドラマ・バラエティ・アニメ・映画・特撮の動画はTELASA(テラサ)'
      if (path.indexOf('/mypage') !== -1) {
        setMetaContent(
          'name',
          'description',
          '購入履歴| ドラマ・バラエティ・アニメ・映画・特撮の動画はTELASA(テラサ)',
        )
        setMetaContent('property', 'og:type', 'website')
        setMetaContent(
          'property',
          'og:title',
          '購入履歴| ドラマ・バラエティ・アニメ・映画・特撮の動画はTELASA(テラサ)',
        )
        setMetaContent(
          'property',
          'og:url',
          'https://base.live.au.com/telasa/mypage/',
        )
        setMetaContent(
          'property',
          'og:image',
          'https://live.au.com/img/telasa_ogimg.jpg',
        )
        setMetaContent(
          'property',
          'og:site_name',
          '購入履歴| ドラマ・バラエティ・アニメ・映画・特撮の動画はTELASA(テラサ)',
        )
        setMetaContent(
          'property',
          'og:description',
          '購入履歴| ドラマ・バラエティ・アニメ・映画・特撮の動画はTELASA(テラサ)',
        )
      }
    } else if (sharedData.siteCode === TicketType.alpha_u) {
      obj.href = '/favicon-alpha-u.ico'
      elmtitle.text = 'αUlive (アルファユーライブ)'
      if (path.indexOf('/mypage') !== -1) {
        setMetaContent(
          'name',
          'description',
          '購入履歴| αUlive (アルファユーライブ)',
        )
        setMetaContent('property', 'og:type', 'website')
        setMetaContent(
          'property',
          'og:title',
          '購入履歴| αUlive (アルファユーライブ)',
        )
        setMetaContent(
          'property',
          'og:url',
          'https://base.live.au.com/alpha-u/mypage/',
        )
        setMetaContent(
          'property',
          'og:image',
          'https://live.au.com/img/alpha-u_ogimg.jpg',
        )
        setMetaContent(
          'property',
          'og:site_name',
          '購入履歴| αUlive (アルファユーライブ)',
        )
        setMetaContent(
          'property',
          'og:description',
          '購入履歴| αUlive (アルファユーライブ)',
        )
      }
    } else {
      obj.href = '/favicon.ico'
      elmtitle.text = 'オンラインライブ配信サービス｜au Live Streaming'
    }
  }, [])

  return (
    <SharedContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/live/:id" element={<Live site="up" />} />
          <Route path="/telasa/live/:id" element={<Live site="telasa" />} />
          <Route path="/alpha-u/live/:id" element={<Live site="alpha-u" />} />
          <Route path="/preview/live/:id" element={<LivePreview />} />
          <Route
            path="/viewing-confirmation"
            element={<ViewingConfirmation site="up" />}
          />
          <Route
            path="/viewing-confirmation/:id"
            element={<ViewingConfirmation site="up" />}
          />
          <Route
            path="/purchase/select/:id"
            element={<PagesSelectContents />}
          />
          <Route path="/purchase/confirm" element={<PageConfirm />} />
          <Route
            path="/purchase/complete"
            element={<PurchaseCompleted site="up" />}
          />

          <Route
            path="/purchase/live-off-hours"
            element={<PageOffHours site="up" />}
          />
          <Route
            path="/telasa/purchase/live-off-hours"
            element={<PageOffHours site="telasa" />}
          />
          <Route
            path="/alpha-u/purchase/live-off-hours"
            element={<PageOffHours site="alpha-u" />}
          />

          <Route
            path="/purchase/error/:id"
            element={<PurchaseError site="up" />}
          />

          <Route path="/viewing/error" element={<ViewingError site="up" />} />
          <Route
            path="/telasa/viewing/error"
            element={<ViewingError site="telasa" />}
          />
          <Route
            path="/alpha-u/viewing/error"
            element={<ViewingError site="alpha-u" />}
          />

          <Route path="/error" element={<CommonError />} />

          <Route path="/login-register" element={<Login site="up" />} />
          <Route
            path="/alpha-u/login-register"
            element={<Login site="alpha-u" />}
          />

          <Route path="/announce/:articleId" element={<PageAnnounce />} />
          <Route path="*" element={<NotFound />} />

          <Route path="/mypage" element={<MyPage site="up" />} />
          <Route path="/telasa/mypage" element={<MyPage site="telasa" />} />
          <Route path="/alpha-u/mypage" element={<MyPage site="alpha-u" />} />

          <Route path="/mypage/setting" element={<MyPageSetting site="up" />} />
          <Route
            path="/telasa/mypage/setting"
            element={<MyPageSetting site="telasa" />}
          />
          <Route
            path="/alpha-u/mypage/setting"
            element={<MyPageSetting site="alpha-u" />}
          />

          <Route
            path="/telasa/purchase/complete"
            element={<PurchaseCompleted site="telasa" />}
          />
          <Route
            path="/alpha-u/purchase/complete"
            element={<PurchaseCompleted site="alpha-u" />}
          />

          <Route
            path="/telasa/purchase/error/:id"
            element={<PurchaseError site="telasa" />}
          />
          <Route
            path="/alpha-u/purchase/error/:id"
            element={<PurchaseError site="alpha-u" />}
          />

          <Route
            path="/telasa/viewing-confirmation"
            element={<ViewingConfirmation site="telasa" />}
          />
          <Route
            path="/telasa/viewing-confirmation/:id"
            element={<ViewingConfirmation site="telasa" />}
          />

          <Route
            path="/alpha-u/viewing-confirmation"
            element={<ViewingConfirmation site="alpha-u" />}
          />
          <Route
            path="/alpha-u/viewing-confirmation/:id"
            element={<ViewingConfirmation site="alpha-u" />}
          />

          {/* 決済方法選択 */}
          <Route path="/purchase/payment" element={<Payment />} />

          {/* 個人情報保持 */}
          <Route path="/purchase/personal-data" element={<PersonalData />} />
        </Routes>
      </BrowserRouter>
    </SharedContextProvider>
  )
}

export default App
