import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MaestroPlayer } from '@/components/maestro/MaestroPlayer/'
import { KKSPlayer } from '@/components/kks/KKSPlayer/'
import { VimeoPlayer } from '@/components/vimeo/'
import { Loader } from '@/components/parts/Loader'
import { Video } from '@/models/video'
import { API } from '@/network/api-client'
import { VideoServiceType, SitePath, TicketType } from '@/enums'
import styles from './styles.module.scss'
import Cookies from 'js-cookie'
import { noOperation } from '@/helpers/utils'
import { IframePlayer } from '@/components/iframe'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export const Live = (props: Props): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [video, setVideo] = useState<Video | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  // siteCode 0:au Live Streaming 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.als

  const errHandler = useCallback(() => {
    navigate(SitePath(siteCode) + '/viewing/error', {
      replace: true,
    })
  }, [navigate])

  const KKS = useMemo(() => {
    return <KKSPlayer eventId={video?.bvLiveId ?? ''} errHandler={errHandler} />
  }, [errHandler, video])

  const Maestro = useMemo(() => {
    return (
      <MaestroPlayer
        slug={video?.maestroSlug ?? ''}
        provider={video?.maestroProviderId ?? ''}
        errHandler={errHandler}
      />
    )
  }, [video, errHandler])

  const Vimeo = useMemo(() => {
    return <VimeoPlayer id={video?.vimeoId ?? ''} />
  }, [video])

  const Iframe = useMemo(() => {
    return (
      <IframePlayer iframe={video?.iframe ?? ''} service={video?.service} />
    )
  }, [video])

  useEffect(() => {
    const fetchVideo = async () => {
      const res = await API.fetchVideo(Number(id))
      setVideo(res.result)
    }

    const checkViewingAvailability = async () => {
      const res = await API.checkViewingAvailability(Number(id))
      if (res.result.isAvailable) {
        const userId = Cookies.get('UserID')
        API.updateUniqueUser(userId ?? '', Number(id)).catch(noOperation)

        setIsLoading(false)
      } else {
        errHandler()
      }
    }

    fetchVideo().catch(() => {
      errHandler()
    })

    checkViewingAvailability().catch(() => {
      errHandler()
    })
  }, [id, errHandler])

  if (isLoading) {
    return (
      <div className={styles.pageWrap}>
        <Loader isShown={true} />
      </div>
    )
  } else if (video) {
    if (video?.service === VideoServiceType.kks) {
      return <div className={styles.pageWrap}>{KKS}</div>
    } else if (video?.service === VideoServiceType.maestro) {
      return <div className={styles.pageWrap}>{Maestro}</div>
    } else if (video?.service === VideoServiceType.vimeo) {
      return <div className={styles.pageWrap}>{Vimeo}</div>
    } else if (
      video?.service === VideoServiceType.immersive ||
      video?.service === VideoServiceType.unityaws
    ) {
      return <div className={styles.pageWrapNoScroll}>{Iframe}</div>
    }
  }

  return (
    <div className={styles.pageWrap}>
      <Loader isShown={true} />
    </div>
  )
}
