import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ListMovieLink } from '@/components/parts/ListMovieLink'
import { ModalWrapper } from '@/components/parts/Modal/ModalWrapper'
import { ModalProvider } from '@/components/parts/Modal'
import { Video } from '@/models/video'
import styles from './styles.module.scss'
import loaderStyles from '../Loader/styles.module.scss'
import clsx from 'clsx'
import { isBeforeNow, isAfterNow, isBetweenDate } from '@/helpers/utils'
import { API } from '@/network/api-client'
import { PersonalConfirmResponse } from '@/models/purchasedHistory'
import { SitePath, TicketType } from '@/enums'

const ScreenMode = {
  dark: styles.dark,
  light: styles.light,
  none: styles.none,
} as const

/** お客様送付先情報型定義 */
interface customerDataInterface {
  name: string | null // お名前
  tel: string | null // 電話番号
  mail: string | null // メールアドレス
  postalCode: string | null // 郵便番号
  address: string | null // 住所
  message: string | null // APIからのメッセージ
}

export type PurchasedHistoryProps = {
  imgSrc: string
  title: string
  ticketTitle?: string
  purchasedDate: string
  start: string
  end: string
  isArchive: boolean
  videos: Video[]
  // 個人情報ID
  personalId: number | null
  // au Live Streaming or TELASA or alpha-u
  siteCode: number
  mode?: keyof typeof ScreenMode
}

export type UnpaidProps = {
  id: number // Stripeテーブルのid
  ticketGruopTitle: string // チケットグループタイトル
  ticketTitle: string // チケットタイトル
  url: string // Stripe支払い画面URL
  mode?: keyof typeof ScreenMode
}

type SelectLiveProps = {
  videos: Video[]
  siteCode: number
}

type CustomerDataProps = {
  // 個人情報ID
  personalId: number
  // au Live Streaming or TELASA or alpha-u
  siteCode: number
}

export const SelectLive: React.FC<SelectLiveProps> = ({ videos, siteCode }) => {
  const isDisabled = (video: Video): boolean => {
    if (isAfterNow(video.open)) {
      return true
    }

    if (video.close && isBeforeNow(video.close)) {
      return true
    }

    return false
  }

  const navigate = useNavigate()
  const movieClicked = useCallback(
    (video: Video) => {
      navigate(SitePath(siteCode) + `/live/${video.id}`)
    },
    [navigate],
  )

  return (
    <div>
      <div className={styles.listWrap}>
        {videos.map(video => (
          <ListMovieLink
            key={video.id}
            text={video.title}
            onClick={video => {
              if (video) {
                movieClicked(video)
              }
            }}
            value={video}
            disabled={isDisabled(video)}
            type="premium"
          />
        ))}
      </div>
    </div>
  )
}

// お客様送付先情報
const CustomerData: React.FC<CustomerDataProps> = ({
  siteCode,
  personalId,
}) => {
  // リンク
  const link =
    (process.env.REACT_APP_TOP_PAGE_URL ?? '') + SitePath(siteCode) + '/help/'

  // 説明文
  const subject = `<p>入力情報に誤りが有った場合、お問い合わせフォームよりご連絡をお願いいたします。<br />詳しくは<a href=${link} target="_blank" style="color: #ff4d00">こちら</a>からヘルプをご確認ください。</p>`
  const [isLoading, setIsLoading] = useState(false)

  /** 表示項目 */
  const [customerData, setCustomerData] = useState<customerDataInterface>({
    name: null, // 名前
    tel: null, // 電話番号
    mail: null, // メールアドレス
    postalCode: null, // 郵便番号
    address: null, // 住所
    message: null, // APIからのメッセージ
  })

  useEffect(() => {
    const getPersonal = async () => {
      try {
        setIsLoading(true)
        // eslint-disable-next-line
        const res: PersonalConfirmResponse = await API.personalConfirm(
          personalId,
        ) // APIからデータ取得

        // APIの呼び出し失敗
        // eslint-disable-next-line
        if (!res.success) {
          setIsLoading(false)
          // eslint-disable-next-line
          setCustomerData({ ...customerData, message: res.message })
          return
        }
        // eslint-disable-next-line
        if (res.personal) {
          setIsLoading(false)

          setCustomerData({
            // eslint-disable-next-line
            name: res.personal.name, // 名前
            // eslint-disable-next-line
            tel: res.personal.tel, // 電話番号
            // eslint-disable-next-line
            mail: res.personal.mail, // メールアドレス
            // eslint-disable-next-line
            postalCode: res.personal.post, // 郵便番号
            // eslint-disable-next-line
            address: res.personal.address, // 住所
            // eslint-disable-next-line
            message: null,
          })
        }
      } catch {
        setIsLoading(false)
        setCustomerData({
          ...customerData,
          message: 'データの取得に失敗しました',
        })
      }
    }
    // eslint-disable-next-line
    getPersonal()
  }, [])

  return (
    <div id="contentAreaPersonalInfo">
      {/* ローディング */}
      {isLoading && (
        <div className={styles.pageWrap}>
          <div className={loaderStyles.loader}></div>
        </div>
      )}
      {!customerData.message && !isLoading && (
        <>
          <div className={styles.personalDataBase}>
            {/* タイトル */}
            <div
              className={`${styles.contentsTitle} ${
                siteCode === TicketType.alpha_u ? styles.alphaUcolor : ''
              }`}
            >
              <p>お客様送付先情報</p>
            </div>
            {/* 説明文 */}
            <div
              className={styles.contentsSubject}
              dangerouslySetInnerHTML={{ __html: subject }}
            ></div>
          </div>
          <div className={`${styles.personalDataBase}`}>
            {/* お名前 */}
            <div className={styles.personalDataSection}>
              <p className={styles.personalDataLabel}>お名前</p>
              <div>
                <p className={styles.personalDataText}>{customerData.name}</p>
              </div>
            </div>
            {/* 電話番号 */}
            <div className={styles.personalDataSection}>
              <p className={styles.personalDataLabel}>電話番号</p>
              <div>
                <p className={styles.personalDataText}>{customerData.tel}</p>
              </div>
            </div>
            {/* メールアドレス */}
            <div className={styles.personalDataSection}>
              <p className={styles.personalDataLabel}>メールアドレス</p>
              <div>
                <p className={styles.personalDataText}>{customerData.mail}</p>
              </div>
            </div>
            {/* 郵便番号 */}
            <div className={styles.personalDataSection}>
              <p className={styles.personalDataLabel}>郵便番号</p>
              <div>
                <p className={styles.personalDataText}>
                  {customerData.postalCode}
                </p>
              </div>
            </div>
            {/* 住所 */}
            <div className={styles.personalDataSection}>
              <p className={styles.personalDataLabel}>住所</p>
              <div>
                <p className={styles.personalDataText}>
                  {customerData.address}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export function PurchasedHistory({
  imgSrc,
  title,
  ticketTitle,
  purchasedDate,
  start,
  end,
  isArchive,
  videos,
  // 個人情報ID
  personalId,
  // TELASA or au Live Streaming or alpha-u
  siteCode,
  mode = 'dark',
}: PurchasedHistoryProps): JSX.Element {
  const isBefore = isAfterNow(start)
  const isFinishsed = isBeforeNow(end)
  const isBetween = isBetweenDate(start, end)

  const btnClassName = clsx(styles.toLive, ScreenMode[mode], {
    [styles['status-now']]: isBetween,
    [styles['backgtound-au']]: isBetween && siteCode === TicketType.alpha_u,
    [styles['status-before']]: !isBetween && isBefore,
    [styles['status-after']]: !isBetween && isFinishsed,
  })

  return (
    <div className={clsx(styles.processWrap, ScreenMode[mode])}>
      <img className={clsx(styles.image, ScreenMode[mode])} src={imgSrc} />
      <div className={styles.textWrap}>
        <p className={styles.title}>{title}</p>
        <p className={clsx(styles.ticketTitle, ScreenMode[mode])}>
          {ticketTitle}
        </p>
        <p className={styles.desc}>購入：{purchasedDate}</p>
        <p className={styles.desc}>視聴可能期間：{start + ' - ' + end}</p>
        {isArchive && (
          <span className={clsx(styles.archive, ScreenMode[mode])}>
            アーカイブ配信あり
          </span>
        )}
        <ModalProvider modalTitle="LIVEの視聴" modalType="onClose">
          <ModalWrapper
            disabled={!isBetween}
            className={btnClassName}
            render={<SelectLive videos={videos} siteCode={siteCode} />}
          />
        </ModalProvider>
        {/* 個人情報IDが存在する場合は、送付先情報の確認を表示 */}
        {personalId && (
          <ModalProvider
            modalTitle="送付先情報の確認"
            modalType="personalInfo"
            className={styles.personalDataModal}
          >
            <ModalWrapper
              disabled={false}
              className={styles.personalData}
              render={
                <CustomerData siteCode={siteCode} personalId={personalId} />
              }
            />
          </ModalProvider>
        )}
      </div>
    </div>
  )
}

// お支払い待ち
export function UnpaidElement({
  id,
  ticketGruopTitle,
  ticketTitle,
  url,
  mode = 'none',
}: UnpaidProps): JSX.Element {
  return (
    <div className={clsx(styles.processWrap, ScreenMode[mode])}>
      <div
        className={`${styles.textWrap} ${styles.myPageUnpaid}`}
        id="unpaidElement"
      >
        {/* 未払いのチケットグループタイトル */}
        <p className={styles.title}>{ticketGruopTitle}</p>
        {/* 未払いのチケットタイトル */}
        <p className={clsx(styles.ticketTitle, ScreenMode[mode])}>
          {ticketTitle}
        </p>
      </div>
      {/* Stripe支払い画面のURL */}
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.unpaidDetail}
      ></a>
    </div>
  )
}
