import React, { useState, useEffect, useCallback } from 'react'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import { TelasaHeaderNoLink } from '@/components/parts/TELASA/Header'
import { TelasaFooter } from '@/components/parts/TELASA/Footer'
import { AuHeader } from '@/components/parts/alpha-u/Header'
import { AuFooter } from '@/components/parts/alpha-u/Footer'
import styles from './styles.module.scss'
import { API } from '@/network/api-client'
import { noOperation } from '@/helpers/utils'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { RadioGroup } from '@/components/parts/RadioGroup'
import { Loader } from '@/components/parts/Loader'
import { Radio } from '@/components/parts/RadioGroup/Radio'
import { TicketType, SitePath } from '@/enums'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export const MyPageSetting = (props: Props): JSX.Element => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [promotionMail, setPromotionMail] = useState(false)
  const [auid, setAuid] = useState('')
  const [isSaved, setIsSaved] = useState(false)

  // siteCode 0:au Live Streaming 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.als

  const errHandler = useCallback(() => {
    navigate(SitePath(siteCode) + '/viewing/error', { replace: true })
  }, [navigate])

  const login = useCallback(() => {
    if (siteCode === TicketType.telasa) {
      const targetUrl = encodeURIComponent(
        (process.env.REACT_APP_BASE_URL ?? '') +
          SitePath(siteCode) +
          '/mypage/',
      )
      const url =
        (process.env.REACT_APP_COCOA_LOGIN_URL ?? '') +
        `?targeturl=${targetUrl}`
      window.location.href = url
    } else {
      navigate(SitePath(siteCode) + '/login-register', { replace: true })
    }
  }, [navigate, siteCode])

  const logout = useCallback(() => {
    const targetUrl = encodeURIComponent(
      process.env.REACT_APP_TOP_PAGE_URL ?? '',
    )
    const url =
      (process.env.REACT_APP_COCOA_LOGOUT_URL ?? '') + `?targeturl=${targetUrl}`

    // CMS 側の記事ページで UserID の Cookie情報を用いて「マイページ」「ログイン」表記を切り替えているため、削除する必要がある
    Cookies.remove('UserID', {
      domain: process.env.REACT_APP_USERID_COOKIE_DOMAIN,
    })
    window.location.href = url
  }, [])

  const initialFetch = useCallback(async () => {
    const res = await API.fetchUserSetting(siteCode)
    setIsLoading(false)
    if (!res.success) {
      errHandler()
      return
    }
    setPromotionMail(res.setting?.promotion_mail ?? false)
  }, [])

  useEffect(() => {
    const ccaautkt = Cookies.get('CCAAUTKT')
    if (!ccaautkt) {
      login()
      return
    }

    const getAuid = async () => {
      const res = await API.getAuid()
      if (res.success) {
        setAuid(res.auid)
      }
    }

    // KDDI の基盤システムに対して認証チェックを行う
    const hostname = window.location.hostname
    if (hostname.indexOf('localhost') === -1) {
      const authenticate = async () => {
        const res = await API.authenticate()
        if (!res.success) {
          Cookies.remove('CCAAUTKT', {
            domain: process.env.REACT_APP_TKT_COOKIE_DOMAIN,
          })
          login()
          return
        }
        getAuid().catch(noOperation)
        initialFetch().catch(noOperation)
      }
      authenticate().catch(() => {
        Cookies.remove('CCAAUTKT', {
          domain: process.env.REACT_APP_TKT_COOKIE_DOMAIN,
        })
        login()
      })
    } else {
      initialFetch().catch(noOperation)
    }
  }, [login])

  const saveSetting = async () => {
    setIsSaved(false)
    setIsLoading(true)
    const res = await API.updateUserSetting(siteCode, promotionMail)
    setIsLoading(false)
    if (!res.success) {
      errHandler()
    }
    setIsSaved(true)
  }

  return (
    <div
      className={`${
        siteCode === TicketType.alpha_u
          ? styles.pageWrapperAu
          : siteCode === TicketType.telasa
          ? styles.pageWrapperTelasa
          : styles.pageWrapperUp
      }`}
      id="scrollableTarget"
    >
      {siteCode === TicketType.als && <Header />}
      {siteCode === TicketType.telasa && <TelasaHeaderNoLink />}
      {siteCode === TicketType.alpha_u && <AuHeader />}
      <div className={styles.contentsWrap}>
        <Loader isShown={isLoading} />
        {!isLoading && (
          <div>
            {siteCode === TicketType.als && (
              <ul className={styles.bredList}>
                <li>
                  <a href={process.env.REACT_APP_TOP_PAGE_URL}>ホーム</a>
                </li>
                <li>
                  <a href={(process.env.REACT_APP_BASE_URL ?? '') + '/mypage/'}>
                    マイページ
                  </a>
                </li>
                <li>
                  <span>アカウント情報の設定</span>
                </li>
              </ul>
            )}

            <h1
              className={`${
                siteCode === TicketType.alpha_u
                  ? styles.pageTitleAu
                  : siteCode === TicketType.telasa
                  ? styles.pageTitleTelasa
                  : styles.pageTitleUp
              }`}
            >
              アカウント情報の設定
            </h1>
            <div
              className={`${
                siteCode === TicketType.alpha_u
                  ? styles.idInfoWrapAu
                  : siteCode === TicketType.telasa
                  ? styles.idInfoWrapTelasa
                  : styles.idInfoWrapUp
              }`}
            >
              <div className={styles.container}>
                <div>
                  <div className={styles.idBlock}>
                    <p className={styles.key}>au ID：</p>
                    <p className={styles.id}>{auid}</p>
                  </div>
                </div>
              </div>
              {siteCode === TicketType.als && (
                <button className={styles.logout} onClick={logout}>
                  ログアウト
                </button>
              )}
            </div>

            <div className={styles.sectionWrap}>
              <h2>受信メールのメールアドレス変更</h2>
              <h3>
                メールアドレスの変更は、au
                IDポータル（外部サイト）より変更のお手続きをお願いいたします。
              </h3>
              <br />
              <h3>
                <a
                  href={process.env.REACT_APP_AUID_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.toAuMyPage}
                >
                  au ID ポータルはこちら
                </a>
              </h3>
            </div>

            <div className={styles.sectionWrap}>
              <h2>メール受信設定</h2>
              <h3>
                オンラインライブに関する、おすすめ情報などをメールでお送りさせていただきます。
              </h3>
              <br />
              <br />
              <h3>
                ※購入完了時に送信されるメール等の重要なメールは「受け取らない」に設定していても送信されます
                のでご了承ください。
              </h3>
              <br />
              <div>
                <RadioGroup value={promotionMail} onChange={setPromotionMail}>
                  <Radio value={true} label="受け取る" dark={true} />
                  <Radio value={false} label="受け取らない" dark={true} />
                </RadioGroup>
              </div>
              <br />
              <div className={styles.buttonWrap}>
                <button onClick={saveSetting}>設定を保存する</button>
              </div>
              <br />
              {isSaved && (
                <div className={styles.buttonWrap}>
                  <p>設定を保存しました。</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {siteCode === TicketType.als && <Footer />}
      {siteCode === TicketType.telasa && <TelasaFooter />}
      {siteCode === TicketType.alpha_u && <AuFooter />}
    </div>
  )
}
