import React, { useState } from 'react'
import { ListTicketAmount, ListType } from '@/components/parts/ListTicketAmount'
import { Button } from '@/components/parts/Button'
import styles from './styles.module.scss'
import { OptionPrice, Ticket } from '@/models/ticket'
import { SerialCode } from '@/components/parts/SerialCode'
import { TicketType } from '@/enums'

export type MembershipContentProps = {
  ticketTitle: string
  generalPrice?: number
  membershipPrice: number
  ticket: Ticket
  options: OptionPrice[]
  optionTotal: number
  siteCode: number
  purhcaseAction: (promotionMail?: boolean) => void
  /** クーポンコード入力フォームの表示・非表示切り替え */
  isShowSerialCode: boolean
  /** クーポンコード */
  serialCode: React.MutableRefObject<string | null>
  /** クーポンコード適用 */
  serialCodeApply: () => void
  /** クーポンコードを適用 & クーポンコードの有効・無効 */
  serialCodeStatus: { isApply: boolean; invalid: boolean }
  /** クーポンコード割引詳細 */
  discount: { type: number; text: string; amount: number }
  /** クーポンコードロック */
  serialCodeLock: boolean
  /** 決済ボタンの制御関数 */
  preventPurchase: (
    termsChecked: boolean,
    isShowSerialCode: boolean,
    current: string | null,
    isApply: boolean,
    invalid: boolean,
    isFreeCoupon: boolean,
  ) => boolean
  /** クーポン不正・使用済みの場合の文言 */
  couponErrorMessage: string
  /** 送付先情報入力(個人情報取得)画面 */
  moveToPersonalData: (promotionMail?: boolean) => void
  // メールマガジン
  promotionMail?: boolean | null
  promotionMailAccepted?: boolean | null
}

export function MembershipContent({
  ticketTitle,
  membershipPrice,
  generalPrice,
  ticket,
  options,
  optionTotal,
  siteCode,
  purhcaseAction,
  isShowSerialCode,
  serialCode,
  serialCodeApply,
  serialCodeStatus,
  discount,
  serialCodeLock,
  preventPurchase,
  couponErrorMessage,
  // 個人情報取得
  moveToPersonalData,
  promotionMail,
  promotionMailAccepted,
}: MembershipContentProps): JSX.Element {
  const [termsChecked, setTermsChecked] = useState(false)
  const [promotionMailChecked, setPromotionMailChecked] = useState(
    (promotionMail ?? false) || (promotionMailAccepted ?? false),
  )
  const [currentSerialCode, setCurrentSerialCode] = useState(serialCode.current)

  // 会員種別
  const isPontapass =
    siteCode === TicketType.als || siteCode === TicketType.alpha_u
  const policyUrl =
    siteCode === TicketType.alpha_u
      ? (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/alpha-u/term/'
      : siteCode === TicketType.telasa
      ? 'https://help.telasa.jp/user-policy/'
      : (process.env.REACT_APP_TOP_PAGE_URL ?? '') + '/term/'

  return (
    <>
      <div className={styles.amountlistWrap}>
        {generalPrice !== undefined && (
          <ListTicketAmount
            type={ListType.linethrough}
            text={ticketTitle}
            amount={generalPrice}
            className="not-border"
          />
        )}
        <ListTicketAmount
          type={ListType.ticket}
          text={
            ticketTitle +
            '\n' +
            (!isPontapass ? '(見放題プラン加入者価格)' : '(Pontaパス会員価格)')
          }
          amount={membershipPrice}
        />
        {options.map(option => (
          <ListTicketAmount
            key={option.id}
            type={ListType.general}
            text={option.optionName}
            amount={option.optionPrice}
          />
        ))}
        {/* クーポン割引が存在する場合 */}
        {discount.amount > 0 && (
          <ListTicketAmount
            type={discount.type}
            text={discount.text}
            amount={-discount.amount}
          />
        )}
        <ListTicketAmount
          type={!isPontapass ? ListType.telasaTotal : ListType.total}
          text="合計（税込）"
          amount={
            // 合計金額が0未満にならないようにする
            optionTotal + membershipPrice - discount.amount < 0
              ? 0
              : optionTotal + membershipPrice - discount.amount
          }
        />
      </div>

      {/* クーポンコード入力フラグが存在する場合、クーポンコード入力フォームを表示する */}
      {isShowSerialCode && (
        <div className={styles.amountlistWrap}>
          <SerialCode
            serialCode={serialCode}
            serialCodeApply={serialCodeApply}
            serialCodeStatus={serialCodeStatus}
            serialCodeLock={serialCodeLock}
            couponErrorMessage={couponErrorMessage}
            setCurrentSerialCode={setCurrentSerialCode}
            isFreeCoupon={false}
          />
        </div>
      )}

      <div className={styles.checkWrap}>
        <div className={styles.checkBoxWrap}>
          <input
            id="confirm-check"
            type="checkbox"
            checked={termsChecked}
            onChange={() => setTermsChecked(prev => !prev)}
          />
          <label htmlFor="confirm-check">
            {/* up用とtelasa用とkddi用で表示する規約が異なる */}
            <a href={policyUrl} target="_blank" rel="noopener noreferrer">
              利用規約
            </a>
            に同意する
          </label>
        </div>
        {!(promotionMailAccepted ?? false) && (
          <div className={styles.checkBoxWrap}>
            <input
              id="promotion-mail-check"
              type="checkbox"
              checked={promotionMailChecked}
              onChange={() => setPromotionMailChecked(prev => !prev)}
            />
            <label htmlFor="promotion-mail-check">
              おすすめ情報などをメールで受け取る
            </label>
          </div>
        )}
      </div>
      {/* 個人情報入力画面が存在しない場合は、そのまま決済方法選択画面に遷移させる */}
      {!ticket.personalForm && (
        <div className={styles.linkWrap}>
          <Button
            text={
              optionTotal + membershipPrice - discount.amount <= 0
                ? '購入する'
                : '決済画面へ進む'
            }
            type="toNext"
            disabled={preventPurchase(
              termsChecked,
              isShowSerialCode,
              currentSerialCode,
              serialCodeStatus.isApply,
              serialCodeStatus.invalid,
              false,
            )}
            onClick={() => {
              purhcaseAction(promotionMailChecked)
            }}
          />
        </div>
      )}
      {/* 個人情報入力画面が存在する場合、個人情報入力画面に進む */}
      {ticket.personalForm && (
        <div className={styles.linkWrap}>
          <Button
            // APIから文言を取得する
            text={`${ticket.personalForm.title}へ進む`}
            type="toNext"
            disabled={preventPurchase(
              termsChecked,
              isShowSerialCode,
              serialCode.current,
              serialCodeStatus.isApply,
              serialCodeStatus.invalid,
              false,
            )}
            onClick={() => {
              moveToPersonalData(promotionMailChecked)
            }}
          />
        </div>
      )}
    </>
  )
}
