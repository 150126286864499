import React from 'react'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import { TelasaHeaderNoLink } from '@/components/parts/TELASA/Header'
import { TelasaFooter } from '@/components/parts/TELASA/Footer'
import { AuHeader } from '@/components/parts/alpha-u/Header'
import { AuFooter } from '@/components/parts/alpha-u/Footer'
import { TicketType } from '@/enums'
import { Error } from '@/components/parts/Error'
import styles from './styles.module.scss'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export const ViewingError = (props: Props): JSX.Element => {
  // siteCode 0:au Live Streaming 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.als

  const linkPage =
    siteCode === TicketType.alpha_u
      ? 'https://alpha-u.io/live/'
      : siteCode === TicketType.telasa
      ? 'https://www.telasa.jp/ppv/'
      : process.env.REACT_APP_TOP_PAGE_URL ?? ''

  return (
    <div
      className={`${
        siteCode === TicketType.alpha_u
          ? styles.pageWrapperAu
          : siteCode === TicketType.telasa
          ? styles.pageWrapperTelasa
          : styles.pageWrapperUp
      }`}
    >
      {siteCode === TicketType.als && <Header />}
      {siteCode === TicketType.telasa && <TelasaHeaderNoLink />}
      {siteCode === TicketType.alpha_u && <AuHeader />}
      <div className={styles.contentsWrap}>
        <Error
          mode={siteCode === TicketType.als ? 'dark' : 'light'}
          link={linkPage}
        />
      </div>
      {siteCode === TicketType.als && <Footer />}
      {siteCode === TicketType.telasa && <TelasaFooter />}
      {siteCode === TicketType.alpha_u && <AuFooter />}
    </div>
  )
}
