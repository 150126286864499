import React, { useState, useEffect, useCallback } from 'react'
import { Header } from '@/components/parts/Header'
import { Footer } from '@/components/parts/Footer'
import { TelasaHeaderNoLink } from '@/components/parts/TELASA/Header'
import { TelasaFooter } from '@/components/parts/TELASA/Footer'
import { AuHeader } from '@/components/parts/alpha-u/Header'
import { AuFooter } from '@/components/parts/alpha-u/Footer'
import {
  PurchasedHistory,
  PurchasedHistoryProps,
  UnpaidElement,
  UnpaidProps,
} from '@/components/parts/PurchasedHistory'
import { Loader } from '@/components/parts/Loader'
import styles from './styles.module.scss'
import { API } from '@/network/api-client'
import { maxDate, minDate, dateTimeFormat, noOperation } from '@/helpers/utils'
import { TicketType, VideoType, SitePath } from '@/enums'
import Cookies from 'js-cookie'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PurchasedHistory as PurchasedHistoryModel } from '@/models/purchasedHistory'
import { Unpaid } from '@/models/unpaid'
import { useNavigate } from 'react-router-dom'

// propsとして受け取る型を定義
type Props = {
  site?: string
}

export const MyPage = (props: Props): JSX.Element => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [list, setList] = useState<PurchasedHistoryProps[]>([])
  // お支払い待ちリスト
  const [unpaid, setUnpaid] = useState<UnpaidProps[]>([])
  // お支払い待ちが存在するか
  const [existUnpaid, setExistUnpaid] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [auid, setAuid] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  // siteCode 0:au Live Streaming 1:TELASA 2:alpha-u
  const siteCode: number =
    props.site === 'alpha-u'
      ? TicketType.alpha_u
      : props.site === 'telasa'
      ? TicketType.telasa
      : TicketType.als

  const login = useCallback(() => {
    if (siteCode === TicketType.telasa) {
      const targetUrl = encodeURIComponent(
        (process.env.REACT_APP_BASE_URL ?? '') +
          SitePath(siteCode) +
          '/mypage/',
      )
      const url =
        (process.env.REACT_APP_COCOA_LOGIN_URL ?? '') +
        `?targeturl=${targetUrl}`
      window.location.href = url
    } else {
      navigate(SitePath(siteCode) + '/login-register', { replace: true })
    }
  }, [navigate, siteCode])

  const setting = useCallback(() => {
    const navigatePath = SitePath(siteCode) + '/mypage/setting'
    navigate(navigatePath, { replace: false })
  }, [navigate, siteCode])

  const formatHistories = useCallback(
    (histories: PurchasedHistoryModel[]) => {
      const news = histories.map(history => {
        const starts = history.videos.map(video => {
          return video.open
        })
        const ends = history.videos
          .map(video => {
            return video.close
          })
          .filter((video): video is string => {
            return video !== undefined
          })

        const isArchive = history.videos.some(video => {
          return (
            video.type === VideoType.archive ||
            video.type === VideoType.liveAndArchive
          )
        })

        return {
          imgSrc:
            (process.env.REACT_APP_TOP_PAGE_URL ?? '') +
            '/thumbnail/s/' +
            history.thumbnailUrl,
          title: history.articleTitle,
          ticketTitle: history.title,
          purchasedDate: dateTimeFormat(history.purchasedDate),
          start: minDate(starts),
          end: maxDate(ends),
          isArchive,
          videos: history.videos,
          // 個人情報ID eslint-disable-next-line
          personalId: history.personalId,
          // au Live Streaming  or TELASA or alpha-u
          siteCode: siteCode,
        } as PurchasedHistoryProps
      })

      setList(prev => [...prev, ...news])
    },
    [siteCode],
  )

  // コンビニ未払いのpropsを/PurchasedHistory/index.tsxに送る
  const formatUnpaid = useCallback((localUnpaid: Unpaid[]) => {
    const news = localUnpaid.map(val => {
      return {
        id: val.id,
        ticketGruopTitle: val.ticketGruopTitle,
        ticketTitle: val.ticketTitle,
        url: val.url,
      } as UnpaidProps
    })
    setUnpaid(news)
  }, [])

  const fetchHistories = useCallback(async () => {
    const res = await API.fetchPurchasedHistory(siteCode, page)

    if (!res.result.length || !res.success) {
      setHasMore(false)
      return
    }

    formatHistories(res.result)
    setHasMore(true)
    setPage(res.currentPage + 1)
  }, [page, formatHistories, siteCode])

  useEffect(() => {
    const ccaautkt = Cookies.get('CCAAUTKT')
    if (!ccaautkt) {
      login()
      return
    }

    const initialFetch = async () => {
      const res = await API.fetchPurchasedHistory(siteCode, 0)
      // コンビニ未払い一覧の取得 (トランザクションIDと支払い方法は一旦NULL)
      const unpaidRes = await API.unpaidTickets(siteCode)
      setIsLoading(false)

      // 未払いリスト
      if (unpaidRes.result.length && unpaidRes.success) {
        formatUnpaid(unpaidRes.result)
      } else {
        setExistUnpaid(false)
      }

      // 購入履歴
      if (res.result.length && res.success) {
        formatHistories(res.result)
        setHasMore(true)
        setPage(1)
      }
    }

    const getAuid = async () => {
      const res = await API.getAuid()
      if (res.success) {
        setAuid(res.auid)
      }
    }

    // KDDI の基盤システムに対して認証チェックを行う
    const hostname = window.location.hostname
    if (hostname.indexOf('localhost') === -1) {
      const authenticate = async () => {
        const res = await API.authenticate()
        if (!res.success) {
          Cookies.remove('CCAAUTKT', {
            domain: process.env.REACT_APP_TKT_COOKIE_DOMAIN,
          })
          login()
          return
        }
        getAuid().catch(noOperation)
        initialFetch().catch(noOperation)
      }

      authenticate().catch(() => {
        Cookies.remove('CCAAUTKT', {
          domain: process.env.REACT_APP_TKT_COOKIE_DOMAIN,
        })
        login()
      })
    } else {
      initialFetch().catch(noOperation)
    }
  }, [login, formatHistories, siteCode])

  const items = list.map((history, index) => (
    <PurchasedHistory
      key={index}
      {...history}
      mode={siteCode === TicketType.als ? 'dark' : 'light'}
    />
  ))

  // お支払い待ち表示
  const unpaidItems = unpaid.map((history, index) => (
    <UnpaidElement key={index} {...history} />
  ))

  return (
    <div
      className={`${
        siteCode === TicketType.alpha_u
          ? styles.pageWrapperAu
          : siteCode === TicketType.telasa
          ? styles.pageWrapperTelasa
          : styles.pageWrapperUp
      }`}
      id="scrollableTarget"
    >
      {siteCode === TicketType.als && <Header />}
      {siteCode === TicketType.telasa && <TelasaHeaderNoLink />}
      {siteCode === TicketType.alpha_u && <AuHeader />}
      <div className={styles.contentsWrap}>
        <Loader isShown={isLoading} />
        {!isLoading && (
          <div>
            {siteCode === TicketType.als && (
              <ul className={styles.bredList}>
                <li>
                  <a href={process.env.REACT_APP_TOP_PAGE_URL}>ホーム</a>
                </li>
                <li>
                  <span>マイページ</span>
                </li>
              </ul>
            )}

            <h1
              className={`${
                siteCode === TicketType.alpha_u
                  ? styles.pageTitleAu
                  : siteCode === TicketType.telasa
                  ? styles.pageTitleTelasa
                  : styles.pageTitleUp
              }`}
            >
              {siteCode === TicketType.als ? 'マイページ' : '購入履歴'}
            </h1>

            <div
              className={`${
                siteCode === TicketType.alpha_u
                  ? styles.idInfoWrapAu
                  : siteCode === TicketType.telasa
                  ? styles.idInfoWrapTelasa
                  : styles.idInfoWrapUp
              }`}
            >
              <div className={styles.container}>
                <div>
                  <div className={styles.idBlock}>
                    <p className={styles.key}>au ID：</p>
                    <p className={styles.id}>{auid}</p>
                  </div>
                  <div className={styles.textWrap}>
                    <p>
                      au IDの確認・情報変更は
                      <a
                        href={process.env.REACT_APP_AUID_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.toAuMyPage}
                      >
                        こちら
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <button className={styles.setting} onClick={setting}>
                アカウント情報
              </button>
            </div>

            {/* お支払い待ちが存在する場合は、お支払い待ちセクションを表示する */}
            {existUnpaid && (
              <div className={styles.historyWrap}>
                <h2>お支払い待ち</h2>
                <p className={styles.unpaidSubject}>
                  コンビニでのお支払いが確認でき次第、購入履歴に反映されます。
                </p>
                {unpaidItems}
              </div>
            )}

            <div className={styles.historyWrap}>
              <h2>購入履歴</h2>
              {!list.length && <p>購入履歴がありません</p>}
              <InfiniteScroll
                dataLength={list.length}
                next={fetchHistories}
                hasMore={hasMore}
                loader={<></>}
                scrollableTarget="scrollableTarget"
              >
                {items}
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
      {siteCode === TicketType.als && <Footer />}
      {siteCode === TicketType.telasa && <TelasaFooter />}
      {siteCode === TicketType.alpha_u && <AuFooter />}
    </div>
  )
}
