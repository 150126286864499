import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MaestroPlayer } from '@/components/maestro/MaestroPlayer/'
import { KKSPlayer } from '@/components/kks/KKSPlayer/'
import { VimeoPlayer } from '@/components/vimeo/'
import { Video } from '@/models/video'
import { Loader } from '@/components/parts/Loader'
import { API } from '@/network/api-client'
import { VideoServiceType, TicketType, SitePath } from '@/enums'
import styles from './styles.module.scss'
import { IframePlayer } from '@/components/iframe'

export const LivePreview: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [video, setVideo] = useState<Video | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const path = window.location.pathname
  let siteCode = TicketType.als
  if (path.startsWith('/telasa')) {
    siteCode = TicketType.telasa
  } else if (path.startsWith('/alpha-u')) {
    siteCode = TicketType.alpha_u
  }
  const errHandler = useCallback(() => {
    navigate(SitePath(siteCode) + '/viewing/error', { replace: true })
  }, [navigate])

  const KKS = useMemo(() => {
    return (
      <KKSPlayer
        eventId={video?.bvLiveId ?? ''}
        errHandler={errHandler}
        isPreview={true}
      />
    )
  }, [errHandler, video])

  const Maestro = useMemo(() => {
    return (
      <MaestroPlayer
        slug={video?.maestroSlug ?? ''}
        provider={video?.maestroProviderId ?? ''}
        errHandler={errHandler}
        isPreview={true}
      />
    )
  }, [video, errHandler])

  const Vimeo = useMemo(() => {
    return <VimeoPlayer id={video?.vimeoId ?? ''} />
  }, [video])

  const Iframe = useMemo(() => {
    return (
      <IframePlayer
        iframe={video?.iframe ?? ''}
        service={video?.service}
        isPreview={true}
      />
    )
  }, [video])

  useEffect(() => {
    const fetchVideo = async () => {
      const res = await API.fetchPreviewVideo(id ?? '')
      setVideo(res.result)
      setIsLoading(false)
    }

    fetchVideo().catch(() => {
      errHandler()
    })
  }, [id, errHandler])

  if (isLoading) {
    return (
      <div className={styles.pageWrap}>
        <Loader isShown={true} />
      </div>
    )
  } else if (video) {
    if (video?.service === VideoServiceType.kks) {
      return <div className={styles.pageWrap}>{KKS}</div>
    } else if (video?.service === VideoServiceType.maestro) {
      return <div className={styles.pageWrap}>{Maestro}</div>
    } else if (video?.service === VideoServiceType.vimeo) {
      return <div className={styles.pageWrap}>{Vimeo}</div>
    } else if (
      video?.service === VideoServiceType.immersive ||
      video?.service === VideoServiceType.unityaws
    ) {
      return <div className={styles.pageWrap}>{Iframe}</div>
    }
  }

  return (
    <div className={styles.pageWrap}>
      <Loader isShown={true} />
    </div>
  )
}
